import { Button, Form, Input, Modal, Spin } from 'antd';
import crypto from 'crypto-js';
import { useRouter } from 'next/router';
import { bool, func, string } from 'prop-types';
import { useState } from 'react';

import { CloseModalSVG } from 'components/iconsSVG/close';
import { getMarket, getLang, setCookie, COOKIES } from 'utils/helpers';
import { useI18n } from 'context/i18n';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import { subscribeNewsletter } from 'actions/general';
import { ThankYouMessage } from 'components/thankYouMessage';
import PrivacyPolicy from 'components/privacyPolicy';

import { IS_NNORMAL } from 'utils/constants/system';

import styles from './style.module.css';

const { SHA256 } = crypto;

const MembersOnlyModal = ({ loading, membersOnlyVisible, setModalMembersOnlyVisible, onFinishOk, nlOrigin, type, href }) => {
  const { locale, t, popupInfo } = useI18n();
  // const [ alreadySub, setAlreadySub ] = useState(false);
  const [error, setError] = useState(null);
  const [loadingEmailRequest, setLoadingEmailRequest] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [emailForm] = Form.useForm();
  const router = useRouter();
  const { query } = router;

  const handleEmailChange = async () => {
    // if (alreadySub) {
    //   setAlreadySub(false);
    // }
    try {
      await emailForm.validateFields(['email']);
      Promise.resolve();
    } catch (e) {
      console.error(e);
    }
  };

  const onFinish = async (values) => {
    setLoadingEmailRequest(true);
    const { utm_campaign, utm_source } = query;
    const payload = {
      email: values.email,
      profile: {
        countryId: getMarket(locale),
        languageId: getLang(locale),
      },
      origin: nlOrigin,
      campaign: utm_campaign,
      channel: utm_source,
    };
    if (type === 'ewallet') {
      payload.ewallet = true;
    }

    if (type === 'content' || type === 'private') {
      payload.campaign = 'MembersOnly_Peli_Kilian';
    }

    // if (isUpdateSub) {
    //   payload.birthDate = dateValue ? dateValue.format() : '';
    //   if (values.gender) {
    //     payload.gender = values.gender.target.value;
    //   }
    //   payload.countryId = selectedCountry;
    //   payload.languageId = selectedLang;
    //   payload.telephone = values.telephone || '';
    //   payload.origin = nlOrigin;
    // }

    try {
      // const isUpdateSub = false;
      // const result = !isUpdateSub ? await subscribeNewsletter(payload) : await updateNewsletter(payload);
      const result = await subscribeNewsletter(payload);
      setCookie(COOKIES.MEMBER_EMAIL, values.email);

      if (result) {
        dataLayerHandleEvent({
          event: 'eventGA',
          eventAct: 'impression',
          eventCat: 'AcquisitionPopup',
          eventLbl: nlOrigin,
        });

        const eventLabelPopUp = window.dataLayer.find((key) => key.eventCat === 'AcquisitionPopup' && key.eventAct === 'impression')?.eventLbl;
        dataLayerHandleEvent({
          event: 'acquisition_popup',
          info_type: 'subscription',
          campaign_detail: popupInfo?.title?.toLowerCase() || '',
          page_type: window.gtmType || '',
        });

        dataLayerHandleEvent({
          event: 'eventGA',
          eventCat: 'AcquisitionPopup',
          eventAct: 'subscription',
          eventLbl: eventLabelPopUp,
        });
        dataLayerHandleEvent({
          event: 'eventGA',
          eventCat: 'NewsletterSubscription',
          eventLbl: nlOrigin,
          eventAct: getMarket(locale),
        });
        dataLayerHandleEvent({
          event: 'mailShadowed',
          mailSHA256: SHA256(values?.email?.toLowerCase()).toString(),
          mailVanilla: values?.email,
        });
        const showJustAdded = true;
        const userIdientified = true;
        onFinishOk(showJustAdded, userIdientified);
      }

      setLoadingEmailRequest(false);

      if (type === 'ewallet' || type.includes('magazine')) {
        if (href && href?.length > 0) {
          window.location.assign(href);
        } else {
          setShowThankYou(true);
        }
      } else if (type === 'content') {
        setShowThankYou(true);
      } else {
        setModalMembersOnlyVisible(false);
      }
    } catch (rqError) {
      console.error(rqError);
      let errorMessage = 'KO: Unexpected Error';
      if (rqError?.data?.errors?.length > 0) {
        errorMessage = rqError?.data?.errors[0].message || 'KO: Unexpected Error';
      }
      setError(errorMessage);
      setLoadingEmailRequest(false);
    }
  };
  const isLoading = loadingEmailRequest || loading;
  const isEwallet = type === 'ewallet';
  return (
    <Modal
      title=""
      visible={membersOnlyVisible}
      footer={null}
      onCancel={!isLoading ? () => setModalMembersOnlyVisible(false) : () => true}
      wrapClassName={styles.modalWrapper}
      destroyOnClose={true}
      closeIcon={type !== 'private' && <CloseModalSVG />}
      maskClosable={!isLoading}
      keyboard={!isLoading}
      closable={type !== 'private' && !isLoading}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modalContent}>
          <div className={`${styles.content} ${showThankYou ? styles.thankYouContent : ''}`}>
            {showThankYou ?
              <ThankYouMessage type={type} className={styles.thankYouMessage} href={href} />
            : <>
                <img
                  src={IS_NNORMAL ? '/assets-new/logo-nnormal.svg' : '/assets-new/product_availability.svg'}
                  alt={'Members Only Logo'}
                  width={IS_NNORMAL ? 200 : 80}
                  height={80}
                />
                <p className={styles.title}>{t('ficha.producto', 'modal.members.only.title', 'Members Only')}</p>
                <p
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: t('ficha.producto', 'modal.members.only.subtitle', 'To buy this exclusive option, please enter your email below'),
                  }}
                />
                <Form
                  form={emailForm}
                  layout="vertical"
                  name="emailForm"
                  onFinish={onFinish}
                  requiredMark="optional"
                  scrollToFirstError
                  className={styles.emailForm}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('validation', 'required.email', 'Please input your email'),
                      },
                      {
                        type: 'string',
                        pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                        message: '',
                      },
                    ]}
                    required
                  >
                    <Input
                      onChange={handleEmailChange}
                      placeholder={t('generico', 'input.email.placeholder', 'Enter your email')}
                      type="email"
                      autoComplete="false"
                    />
                  </Form.Item>

                  {/* puesto que solo usa origin para la etiqueta y quieren la default, no le pasamos el nlOrigin */}
                  <PrivacyPolicy hideConditions />

                  {error && <span className="error">{error}</span>}

                  <Form.Item className={styles.submitItem}>
                    <Button type="default" htmlType="submit" className={styles.submitBtn} block size="large">
                      {isEwallet || type.includes('magazine') || type === 'content' || type === 'private' ?
                        t('compra.formulario', 'tws.enviar', 'Send')
                      : `${t('ficha.producto', 'members.only.shop')} ${t('ficha.producto', 'members.only')}`}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            }
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

MembersOnlyModal.defaultProps = {
  loading: false,
  membersOnlyVisible: false,
  setModalMembersOnlyVisible: () => true,
  onFinishOk: () => true,
  nlOrigin: 'pdp_members_only_popup',
  type: 'members_only',
  href: '',
};

MembersOnlyModal.propTypes = {
  loading: bool,
  membersOnlyVisible: bool,
  setModalMembersOnlyVisible: func,
  onFinishOk: func,
  nlOrigin: string,
  type: string,
  href: string,
};

export default MembersOnlyModal;
